import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import HTMLContent from "../components/HTMLContent";
import PageBanner from "../components/PageBanner";
import Icon from "../components/Icon";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";
import YouTubeVideo from "../components/YouTubeVideo";

const icons = {
  People: <Icon iconName="users" width={40} />,
  Clock: <Icon iconName="clock" width={40} />,
  Abacus: <Icon iconName="abacus" width={40} />,
  Telescope: <Icon iconName="telescope" width={40} />,
  Scroll: <Icon iconName="scroll" width={40} />,
  Clipboard: <Icon iconName="clipboard" width={40} />,
  Handshake: <Icon iconName="handshake" width={40} />,
  Ratio: <span className="ratio">6 : 1</span>,
  Money: <Icon iconName="badgeDollar" width={40} />,
  Cap: <Icon iconName="cap" width={40} />,
};

export const ProgramPageTemplate = ({
  description,
  banner,
  overviewSection,
  schoolSection,
  bridgesSection,
  OverviewDescription,
  SchoolDescription,
  BridgesDescription,
}) => {
  return (
    <>
      <PageBanner>
        <h1 className="page-banner-header">OUR PROGRAM</h1>
        <p className="width-container">{description}</p>
      </PageBanner>
      <div className="layout-container">
        <div className="grid-container top-image-container program-top">
          <span className="plus-icon">
            <Icon iconName="plus" width={36} />
          </span>
          <div className="grid-item-2">
            <a
              href="#middle-school-and-high-school"
              className="program-img-container left-img"
            >
              <div className="program-img">
                <PreviewCompatibleImage imageData={banner.schoolImage} />
              </div>
              <div className="circle" />
              <div className="content">
                <p>{`Middle School & High School`}</p>
                <button className="btn">LEARN MORE</button>
              </div>
            </a>
          </div>
          <div className="grid-item-2 program-img-container right-img">
            <a
              href="#bridges-transition-program"
              className="program-img-container right-img"
            >
              <div className="program-img">
                <PreviewCompatibleImage imageData={banner.bridgesImage} />
              </div>
              <div className="circle" />
              <div className="content">
                <p>{`Bridges Transition Program`}</p>
                <button className="btn">LEARN MORE</button>
              </div>
            </a>
          </div>
        </div>
        <section className="body-padding">
          <div className="grid-container add-width">
            <div className="grid-item grid-item-2">
              <h3>{overviewSection.heading}</h3>
              {OverviewDescription}
            </div>
            <div className="grid-item grid-item-2">
              <div className="body-img">
                <PreviewCompatibleImage
                  imageData={overviewSection.image}
                  gatsbyImageProps={{ fadeIn: false }}
                />
              </div>
            </div>
          </div>
        </section>
        <section className="body-id-section" id="middle-school-and-high-school">
          <div className="landing-header-container text-center">
            <h3>{schoolSection.heading}</h3>
          </div>
          <div className="grid-container add-width">
            <div className="grid-item grid-item-2">
              <YouTubeVideo
                src={schoolSection.video}
                height={340}
                width={"100%"}
              />
            </div>
            <div className="grid-item grid-item-2">
              {SchoolDescription}
              <table>
                <tbody>
                  {schoolSection.bulletItems.map(({ item }, index) => {
                    return (
                      <tr key={`row-${index}`}>
                        <td className="check-cell">
                          <div className="check-container">
                            <Icon iconName="check" width={20} />
                          </div>
                        </td>
                        <td>{item}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
          <div className="text-center addl-benefits-container">
            <h5>{schoolSection.additionalHeading}</h5>
            <div className="grid-container">
              {schoolSection.additionalBenefits.map(
                ({ description, icon }, index) => {
                  return (
                    <div
                      className="grid-table-item"
                      key={`school-item-${index}`}
                    >
                      <div>{icons[icon]}</div>
                      <p>{description}</p>
                    </div>
                  );
                }
              )}
            </div>
          </div>
        </section>
        <section className="body-id-section" id="bridges-transition-program">
          <div className="landing-header-container text-center">
            <h3>{bridgesSection.heading}</h3>
          </div>
          <div className="grid-container add-width row-reverse-section">
            <div className="grid-item grid-item-2">
              <div className="body-img">
                <YouTubeVideo
                  width={"100%"}
                  height={380}
                  src={bridgesSection.video}
                />
              </div>
            </div>
            <div className="grid-item grid-item-2">
              {BridgesDescription}
              <div className="bottom-btn-container">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.schoolcues.com/Portal/PreEnrollment/Index/3l1y6Louhbp"
                  className="btn primary-btn full-width-sm"
                >
                  REQUEST MORE INFORMATION
                </a>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

ProgramPageTemplate.propTypes = {
  description: PropTypes.string,
  banner: PropTypes.object,
  overviewSection: PropTypes.object,
  schoolSection: PropTypes.object,
  bridgesSection: PropTypes.object,
  OverviewDescription: PropTypes.node,
  SchoolDescription: PropTypes.node,
  BridgesDescription: PropTypes.node,
};

const ProgramPage = ({ data }) => {
  const {
    markdownRemark: {
      frontmatter: {
        description,
        banner,
        overviewSection,
        schoolSection,
        bridgesSection,
      },
    },
  } = data;

  return (
    <Layout
      pageTitle={`Our Program`}
      ogUrl="/program/"
      pageDecription="Philips Academy is a nurturing, creative learning environment for students requiring life skills education along with academic instruction. Learn more about what makes our program so unique and effective."
    >
      <ProgramPageTemplate
        description={description}
        banner={banner}
        overviewSection={overviewSection}
        schoolSection={schoolSection}
        bridgesSection={bridgesSection}
        OverviewDescription={
          <HTMLContent
            content={
              data.markdownRemark.fields.frontmatterMarkdownHTML.overviewSection
                .markdown
            }
          />
        }
        SchoolDescription={
          <HTMLContent
            content={
              data.markdownRemark.fields.frontmatterMarkdownHTML.schoolSection
                .markdown
            }
          />
        }
        BridgesDescription={
          <HTMLContent
            content={
              data.markdownRemark.fields.frontmatterMarkdownHTML.bridgesSection
                .markdown
            }
          />
        }
      />
    </Layout>
  );
};

ProgramPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default ProgramPage;

export const programPageQuery = graphql`
  query ProgramPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        banner {
          schoolImage {
            childImageSharp {
              fluid(maxWidth: 850, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          bridgesImage {
            childImageSharp {
              fluid(maxWidth: 850, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        overviewSection {
          heading
          image {
            childImageSharp {
              fluid(maxWidth: 850, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        schoolSection {
          heading
          video
          bulletItems {
            item
          }
          additionalHeading
          additionalBenefits {
            icon
            description
          }
        }
        bridgesSection {
          heading
          video
          linkURL
        }
        description
      }
      fields {
        frontmatterMarkdownHTML {
          overviewSection {
            markdown
          }
          schoolSection {
            markdown
          }
          bridgesSection {
            markdown
          }
        }
      }
    }
  }
`;
